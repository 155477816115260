import React from 'react';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';

import { LoadableAfterPaint } from '@confluence/loadable';

export { ContentClassificationTag } from './ContentClassificationLevel/ContentClassificationTag';

export { ClassificationChangeWarning } from './ClassificationChangeWarning';

export { ClassificationLevelError } from './ContentClassificationLevel/ClassificationLevelError';

export { Warning } from './constants/Warning';

export { ClassificationState } from './constants/ClassificationState';

export { NO_CLASSIFICATION } from './constants/NoClassification';
export { useClassificationChangeState } from './hooks/useClassificationChangeState';

export { FormattedSectionMessage } from './FormattedSectionMessage';

export { useIsDataClassificationEnabledForOrg } from './hooks/useIsDataClassificationEnabledForOrg';

export { useIsClassificationEnabledForContentTypeExpansion } from './hooks/useIsClassificationEnabledForContentTypeExpansion';

export type { ContentClassificationMetadata } from './hooks/useContentClassification';

export type { SpaceClassificationMetadata } from './hooks/useDefaultAndSpaceClassificationLevels';

export {
	mockContentClassificationMetadata,
	mockSpaceClassificationMetadata,
	typedMockPublishedClassification,
} from './__local_mocks__/classifications-mock';

const ClassificationPlaceholderStyles = xcss({
	height: '26px',
});

// This placeholder should match the one in EditorActionBar
const ClassificationPlaceholder = <Box xcss={ClassificationPlaceholderStyles} />;

export const DataClassification = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-DataClassification" */ './DataClassification'))
			.DataClassification,
});

export const EditorContentClassificationTag = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorContentClassificationTag" */ './ContentClassificationLevel/EditorContentClassificationTag'
			)
		).EditorContentClassificationTag,
	loading: () => ClassificationPlaceholder,
});

export const EditorDataClassification = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorDataClassification" */ './EditorComponent/EditorDataClassification'
			)
		).EditorDataClassification,
});

export const ContentTypeClassification = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentTypeClassification" */ './ContentTypeClassification/ContentTypeClassification'
			)
		).ContentTypeClassification,
	loading: (props) => {
		const { contentClassificationMetadata, readOnly } = props;
		const { hasNoClassification } = contentClassificationMetadata;
		const shouldNotRenderLoader = readOnly && hasNoClassification;
		if (shouldNotRenderLoader) return null;
		return React.createElement(
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
			styled.div({
				height: '24px',
			}),
			{},
			'',
		);
	},
});

export const DataClassificationHoverButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DataClassificationHoverButton" */ './EditorComponent/DataClassificationHoverButton'
			)
		).DataClassificationHoverButton,
	loading: () => ClassificationPlaceholder,
});
