import type { Color } from '@atlassian/data-classification-level';

import type { ClassificationLevel } from '../ClassificationRadioOption';
import type { ContentClassificationMetadata } from '../hooks/useContentClassification';
import { ErrorState } from '../constants/ErrorState';
import { FlagStatus } from '../constants/FlagStatus';
import { Status } from '../constants/Status';

const MOCK_OPERATIONS_WITH_FULL_PERMISSIONS = [
	{ targetType: 'space', operation: 'read' },
	{ targetType: 'space', operation: 'update' },
	{ targetType: 'space', operation: 'delete' },
	{ targetType: 'space', operation: 'export' },
	{ targetType: 'space', operation: 'restrict_content' },
	{ targetType: 'space', operation: 'administer' },
	{ targetType: 'page', operation: 'create' },
	{ targetType: 'blogpost', operation: 'create' },
	{ targetType: 'attachment', operation: 'create' },
	{ targetType: 'comment', operation: 'create' },
];

export const mockPublishedClassification = {
	id: '1',
	name: 'Classification 1',
	description: 'Description 1',
	color: 'RED',
	status: 'PUBLISHED',
};

export const mockArchivedClassification = {
	id: '10',
	name: 'Archived Classification',
	description: 'Archived',
	status: 'ARCHIVED',
};

export const typedMockPublishedClassification: ClassificationLevel = {
	id: '1',
	name: 'Classification 1',
	description: 'Description 1',
	definition: 'This is a definition',
	guideline: 'This is a guideline',
	color: 'RED' as Color,
	status: Status.PUBLISHED,
};

export const typedMockPublishedClassificationTwo: ClassificationLevel = {
	id: '2',
	name: 'Classification 2',
	description: 'Description 2',
	definition: 'This is a definition',
	guideline: 'This is a guideline',
	color: 'RED' as Color,
	status: Status.PUBLISHED,
};

type Classification = typeof mockPublishedClassification;

export const mockClassifications = [
	mockPublishedClassification,
	{
		id: '2',
		name: 'Classification 2',
		description: 'Description 2',
		color: 'BLUE',
		status: 'PUBLISHED',
	},
	{
		id: '3',
		name: 'Classification 3',
		description: null,
		color: 'GREEN',
		status: 'PUBLISHED',
	},
	mockArchivedClassification,
];

/**
 * Creates a mock space with the provided properties.
 */
const createMockSpace = ({
	id = 'MOCK_SPACE_ID',
	key = 'MOCK_SPACE_KEY',
	name = 'MOCK_SPACE_NAME',
	defaultClassificationLevelId = '',
}) => ({
	id,
	key,
	name,
	defaultClassificationLevelId,
	homepage: {
		id: 'MOCK_HOMEPAGE_ID',
		title: 'MOCK_HOMEPAGE_TITLE',
	},
	operations: MOCK_OPERATIONS_WITH_FULL_PERMISSIONS,
	history: {
		createdBy: {
			displayName: 'MOCK_SPACE_CREATOR',
			__typename: 'KnownUser',
		},
	},
	icon: {
		path: 'https://mock-space-icon.com',
	},
	containsExternalCollaborators: false,
});

export type MockSpace = ReturnType<typeof createMockSpace>;

export const getMockUnclassifiedSpace = () =>
	createMockSpace({
		id: '1',
		key: '1',
		name: 'Current space',
	});

export const getMockClassifiedSpace = () =>
	createMockSpace({
		id: '2',
		key: '2',
		name: 'classified space',
		defaultClassificationLevelId: mockPublishedClassification.id,
	});

export const getMockArchivedClassificationSpace = () =>
	createMockSpace({
		id: '3',
		key: '3',
		name: 'archived space',
		defaultClassificationLevelId: mockArchivedClassification.id,
	});

export const mockSpaces = () => ({
	nodes: [getMockUnclassifiedSpace(), getMockClassifiedSpace()],
});

/**
 * Creates a mock resolver for content level classifications.
 * This resolver will return the classification level for the contentId provided in the query.
 * @param contentToClassifications - a map of contentId to classification levels
 */
export const createMockContentWithClassification =
	(contentToClassifications: Record<string, Classification | null>) =>
	// @ts-ignore
	(_, __, ___, info) => {
		const contentId = info.variableValues.contentId;
		const name = contentToClassifications[contentId]?.name;
		const id = contentToClassifications[contentId]?.id;
		const status = contentToClassifications[contentId]?.status;
		return {
			name,
			id,
			status,
		};
	};

export const getMockClassificationLevels = () => mockClassifications;

/**
 * Creates a mock resolver for space level classifications.
 * This resolver will return the space default classification for the spaceKey provided in the query.
 * @param spacesToClassifications - a map of spaceKey to space classification levels
 */
export const createMockSpacesWithClassification =
	(spacesToClassifications: Record<string, MockSpace | null>) =>
	// @ts-ignore
	(_, __, ___, info) => {
		const spaceKey = info.variableValues.spaceKey;

		const defaultClassificationLevelId =
			spacesToClassifications[spaceKey]?.defaultClassificationLevelId;

		return {
			defaultClassificationLevelId,
		};
	};

export const mockContentClassificationMetadata: ContentClassificationMetadata = {
	classification: null,
	spaceClassification: null,
	spaceDefaultIsOverridden: false,
	getClassificationLevelFromId: () => undefined,
	errorState: ErrorState.NO_ERROR,
	contentLevelError: undefined,
	spaceLevelError: undefined,
	shouldDisplayClassification: true,
	updateContentDataClassificationLevel: () => new Promise<any>(() => {}),
	deleteContentDataClassificationLevel: () => new Promise<any>(() => {}),
	setClassification: () => {},
	flagStatus: FlagStatus.NONE,
	setFlagStatus: () => {},
	contentStatusContext: 'draft-only',
};

export const mockSpaceClassificationMetadata = {
	classificationLevels: [],
	defaultClassification: null,
	defaultId: '',
	loading: false,
	isEnabled: false,
	spaceName: '',
	totalCurrentPages: 0,
	setUnfilteredClassificationId: () => {},
};

// maps spaceKey to default space classification level
// used to assign the corresponding default classification level to a space
export const defaultSpaceClassificationMap = createMockSpacesWithClassification({
	[getMockUnclassifiedSpace().key]: getMockUnclassifiedSpace(),
	[getMockClassifiedSpace().key]: getMockClassifiedSpace(),
	[getMockArchivedClassificationSpace().key]: getMockArchivedClassificationSpace(),
});
